<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <h3><b>Tour Bookings:</b></h3>
          <br>
          <em v-if="isLoading">Loading bookings...</em>
          <!-- <span v-if="users.error" class="text-danger">ERROR: {{ users.error }}</span>
          <div v-if="users.items"> -->

          <div class="search_add_section row">
            <div class="col-md-6">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by package name"
                class="search_by_input"
                spellcheck="off"
              />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading"
            class="table-responsive"
          >
            <data-tables
              :data="bookings"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getReservations"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Bookings Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Bookings</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Safari Package"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span> <strong> Name:</strong> {{ scope.row.room_name }} </span><br />
                  <span> <strong> No of Rooms:</strong> {{ scope.row.number_of_rooms }}</span><br />
                  <span> <strong>No of Adults:</strong> {{ scope.row.number_adult_guest }} </span><br />
                  <span> <strong> No of Children:</strong> {{ scope.row.number_children_guest }} </span><br />
                  <span><strong> Amount:</strong> ${{ scope.row.amount }} </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Guest Info"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span> <strong>Name:</strong> {{ scope.row.first_name }} {{ scope.row.first_name }} </span><br />
                  <span> <strong>Email:</strong> {{ scope.row.email }} </span><br />
                  <span> <strong>Country:</strong> {{ scope.row.country }} </span><br />
                  <span><strong> Address:</strong> {{ scope.row.address }} </span><br />
                  <span> <strong>Booking Date: </strong>{{ formattedDate(scope.row.booking_date) }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Check-In/Check-Out"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <span> <strong>Check-In Date:</strong> {{ formattedDate(scope.row.check_in_date) }} </span><br />
                  <span> <strong>Check-Out Date:</strong> {{ formattedDate(scope.row.check_out_date) }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Payment Status"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <span style="color:green">
                    {{ scope.row.payment_status }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="true"
                        @click="deleteFaculty(scope.row.faculty_id)"
                        icon="el-icon-more"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="true"
                        @click="deleteFaculty(scope.row.faculty_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,
      bookings: [],

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "room_name",
          value: "",
        },
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getReservations();
  },

  methods: {
    async getReservations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`bookings/get_all`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.bookings = request.data.bookings;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

input:focus {
  border: 0.01em solid #409eff !important;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>